import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { StoreTemplate } from '../../../context/TemplateContext/TemplateContext';
import { useUserData } from '../../../context/UserDataContext/UserDataContext';
import { WixStudioLogo } from '../WixStudioLogo/WixStudioLogo';
import { mobileWixStudioToolbarDataHooks } from './MobileWixStudioToolbar.dataHooks';
import Desktop from './Desktop.svg';

import s from './MobileWixStudioToolbar.scss';

interface MobileWixStudioToolbarProps extends WithTranslation {
  dataHook?: string;
  template: StoreTemplate;
}

const MobileWixStudioToolbarCmp: FC<MobileWixStudioToolbarProps> = ({ t, dataHook, template }) => {
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const userData = useUserData();

  const onButtonClick = () => {
    if (redirectUrl) {
      const anonUrl = `https://users.wix.com/signin/signup/password?studio=true&loginDialogContext=signup&loginCompName=SignUp_H&postSignUp=${encodeURIComponent(
        redirectUrl,
      )}&postLogin=${encodeURIComponent(redirectUrl)}&forceRender=true`;
      window.location.href = userData.isLoggedIn ? redirectUrl : anonUrl;
    }
  };

  useEffect(() => {
    const url = new URL(window.location.toString()).searchParams.get('redirectUrl');
    if (url) {
      const urlObj = new URL(url);
      if (urlObj.host === 'www.wix.com') {
        setRedirectUrl(url);
      }
    }
  }, []);

  return (
    <>
      <div className={s.toolbar} data-hook={dataHook}>
        <WixStudioLogo dataHook={mobileWixStudioToolbarDataHooks.logo} />
        {redirectUrl && (
          <button onClick={onButtonClick} className={s.button}>
            {t('template.viewer.saveTemplate.button')}
          </button>
        )}
      </div>
      <div className={s.goToDesktop} data-hook={mobileWixStudioToolbarDataHooks.goToDesktop}>
        <Desktop />
        <div>{t('template.viewer.goToBiggerScreen')}</div>
      </div>
    </>
  );
};

export const MobileWixStudioToolbar = withTranslation()(MobileWixStudioToolbarCmp);
